import { animate, state, style, transition, trigger } from '@angular/animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { firestore } from 'firebase';
import { BCrediFinancingTypeOptions, BCrediRealtyTypeOptions } from 'functions/src/models/BCredi';
import { CreditasRealtyRegistryOptions, CreditasRealtyTypeOptions } from 'functions/src/models/Creditas';
import { Customer } from 'functions/src/models/Customer';
import { BankerNote } from 'functions/src/models/Notes';
import { BasicStructure } from 'functions/src/models/common/BasicStructure';
import { timestampForDate } from 'functions/src/utils/dates';
import _, { isDate } from 'lodash';
import _moment from 'moment';
import { MASKS } from 'ng-brazil';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { CreditStatusDialogComponent } from 'src/app/components/credit-status-dialog/credit-status-dialog.component';
import { ForwardNotesComponent } from 'src/app/components/forward-notes/forward-notes.component';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { FinanciamentoHandler } from 'src/app/core/handler/financiamento.handler';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { environment } from 'src/environments/environment';
import { User } from '../../../../../functions/src/models/User';
import { BasicRegister } from '../../../../../functions/src/models/common/BasicRegister';
import { GroupOppsEntity } from '../../../../../functions/src/models/opportunity/GroupOppsEntity';
import { Opportunity } from '../../../../../functions/src/models/opportunity/Opportunity';
import { OpportunityStage } from '../../../../../functions/src/models/opportunity/OpportunityStage';
import { InstituicaoFinanceira } from '../../../admin/instituicao-financeira/instituicao-financeira';
import { TableDialogComponent } from '../../../components/table-dialog/table-dialog.component';
import { CustomerService } from '../../../customer/services/customer.service';
import { FinancialInstitutionsService } from '../../../customer/services/financial-institutions.service';
import { OpportunityPhasesService } from '../../../customer/services/opportunity-phases.service';
import { CadastrosGeraisItem } from '../../cadastros-gerais/cadastros-gerais';
import { CadastrosGeraisService } from '../../cadastros-gerais/cadastros-gerais.service';
import { BankerNotesComponent } from '../../components/banker-notes/banker-notes.component';
import { RecurrenceAdminDialogComponent } from '../../components/recurrence-admin/recurrence-admin-dialog.component';
import { InstituicaoFinanceiraService } from '../../instituicao-financeira/instituicao-financeira.service';
import { NotesService } from '../../services/notes.service';
import { UserManagementService } from '../../services/user-management.service';
import { OpportunityHistoryService } from './../../../customer/services/opportunity-history.service';
import { OpportunityManagementService } from './../../services/opportunity-management.service';

interface Recipient {
  email: string;
  valid: boolean;
  color: string;
}

interface Food {
  value: string;
  viewValue: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LLL',
  },
  display: {
    dateInput: 'LLL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LLL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-customer-opportunity',
  templateUrl: './customer-opportunity.component.html',
  styleUrls: ['./customer-opportunity.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomerOpportunityComponent implements OnInit, OnChanges, OnDestroy {
  @Input() customer: Customer;
  @Input() loggedUser: User;
  @Input() opportunities: Opportunity[];
  @Input() startCommittee: boolean;
  loggedAdminSubscription: Subscription;
  loggedAdmin: User;
  @Output() needToCheckDocuments = new EventEmitter<any>();

  groupOpps: GroupOppsEntity[] = [
    {
      id: 'lines-active',
      label: 'Linhas Atuais',
      selected: true,
      opportunities: []
    }
  ];

  oppGroupSelected: GroupOppsEntity;
  oppHistorySubscription: Subscription;

  dataSource: MatTableDataSource<Opportunity>;
  matSortCredit: MatSort;
  columnsCreditoPre = [
    'imagem',
    'institutionName',
    'deadlineForMonth',
    'guarantees',
    'pendingToAdm',
    /* 'decisionCustomer',  */ 'actions',
    'opportunityPhases'
  ];
  guaranteesList: any;
  linesList: any;
  validityInfo: { message: string, date: Date }[] = []
  daysUntilExpiration: number;
  validityMessages: { [id: string]: string } = {};

  @ViewChild('matSortCredit', { static: false }) set matSortC(ms: MatSort) {
    this.matSortCredit = ms;
    this.dataSource.sort = this.matSortCredit;
  }
  @ViewChild('linhasCredAtivosSelecionados', { static: false }) linhasCredAtivosSelecionados;
  constructor(
    private ums: UserManagementService,
    private oppService: OpportunityManagementService,
    private authService: AuthService,
    private cadastrosGeraisService: CadastrosGeraisService,
    private dialog: MatDialog,
    private financHandler: FinanciamentoHandler,
    private formBuilder: FormBuilder,
    private logger: LogService,
    private http: HttpClient,
    private router: Router,
    private institutionService: InstituicaoFinanceiraService,
    private financialInstitutionsService: FinancialInstitutionsService,
    private notesService: NotesService,
    private oppHistoryService: OpportunityHistoryService,
    private oppPhasesService: OpportunityPhasesService,
    private customerService: CustomerService,
  ) {
    this.birthDateControl = new FormControl;
    this.dataSource = new MatTableDataSource([]);
    this.datePipe = new DatePipe('pt-BR');
    this.stageOpportunityInicial = oppService.getOppStageFromNumber(20);
    this.opportunityStepsOptions = oppService.getAllOppStages();
    this.oppService.getGuarantees().subscribe((data: any) => {
      this.guaranteesList = data.guaranteesSelect;
    });
    
    this.oppService.getLines().subscribe((data: any) => {
      this.linesList = data.linesSelect;
    });
    
  }
  institutions: InstituicaoFinanceira[];
  linesCreditActive: Opportunity[];

  expandedElement: Opportunity;
  expandedElementId: string;
  closingReasonOptions: CadastrosGeraisItem[];
  datePipe: DatePipe;
  agreedTerms: boolean;
  customerUID: string;
  closeOppsFormGroup: FormGroup;
  runCloseOpps = false;
  runOpportunities = false;
  isAddingCreditLine = false;
  showButton = true;
  showDate = true;
  readonly MASKS = MASKS;

  linesCreditActiveSubscription: Subscription;
  institutionsActivesSubscription: Subscription;
  getDetalhesBancoSubscription: Subscription;
  getActiveMotivesSubscription: Subscription;
  readonly opportunityStepsOptions: OpportunityStage[];
  readonly stageOpportunityInicial: OpportunityStage;
  selectedValueStage: any;
  limiteCreditoStr = 'R$ 0,00';
  limiteCredito = 0;

  // Send documents by e-mail
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  recipients: Recipient[] = [];
  copyTo: Recipient[] = [];
  removable = true;
  readonly recipientsLimit = 10;
  visible = true;
  selectable = false;
  hasDifferentProbabilityButton = false
  selectableCopyTo = false;
  addOnBlur = true;
  addOnBlurCopyTo = true;
  disabled = true;
  hasErrorRecipients = false;
  hasErrorCopyTo = false;
  runReprocesseRecurrence = false;


  // Message Preview
  @ViewChild('messagePreview', { static: false }) messagePreview: ElementRef;
  subject = '';
  message = '';
  selectedDocuments: any = {};

  // Big Data Corp
  readonly Object = Object;
  bigdatacorpData: any;
  bigdatacorpSubscription: Subscription;
  isProcessingBDC = false;

  // BCredi Integration

  bcrediRealty = {
    // pode aproveitar realEstate da integração creditas abaixo?
    warrantyState: undefined,
    warrantyAddress: undefined,
    warrantyZipCode: undefined,
    value: undefined,
    type: undefined,
  };

  //  birthDate;
  //  isProcessingIntegration = false;

  readonly bcrediRealtyTypeOptions = [
    { name: 'Imóvel residencial com escritura', value: BCrediRealtyTypeOptions.REGISTERED_RESIDENTIAL_PROPERTY },
    { name: 'Imóvel comercial com escritura', value: BCrediRealtyTypeOptions.REGISTERED_COMMERCIAL_PROPERTY },
    { name: 'Apartamento com escritura', value: BCrediRealtyTypeOptions.REGISTERED_APARTMENT },
    { name: 'Imóvel sem escritura ou terra', value: BCrediRealtyTypeOptions.NOT_REGISTERED_OR_LAND },
  ];

  readonly bcrediFinancingTypeOptions = [
    { name: 'Crédito com garantia de imóvel', value: BCrediFinancingTypeOptions.PROPERTY_SECURED_CREDIT },
    { name: 'Financiamento imobiliário', value: BCrediFinancingTypeOptions.REAL_ESTATE_FINANCING },
    { name: 'Antecipação de renda', value: BCrediFinancingTypeOptions.ANTICIPATED_INCOME },
  ];

  // Iouu Integration

  // -> aqui serão declaradas as variáveis dos campos exclusivos da Iouu a serem usadas em additionalData nas funções da Iouu abaixo

  // End Iouu Integration

  // Creditas Integration
  readonly years = _.fill(Array(new Date().getFullYear() - 1983), undefined).map(
    (v, i) => new Date().getFullYear() - i
  );
  manufacturers = [];
  models = [];
  vehicle = {
    year: undefined,
    manufacturer: undefined,
    model: undefined,
  };
  realEstate: any = {
    hasDeed: undefined,
    zipCode: undefined,
    address: undefined,
    number: undefined,
    neighborhood: undefined,
    city: undefined,
    state: undefined,
    country: undefined,
    value: undefined,
    debt: undefined,
    type: undefined,
  };
  birthDateControl: FormControl;
  isProcessingIntegration = false; // o que faz?

  // Banker Notes
  isBankerNoteCreateMode = false;
  isLoadingBankerNotes = false;
  newBankerNote: BankerNote = {
    message: '',
  };
  bankerNotes: BankerNote[] = [];
  bankerNotesSubscription: Subscription;
  readonly MAX_BANKER_NOTES_PREVIEW_QUANTITY = 3;

  // User Notes
  createdBy = {};

  // Opportunities
  loadingOpportunity: string = null;


  readonly hasDeedOptions = [
    { name: 'Sim', value: CreditasRealtyRegistryOptions.YES },
    { name: 'Não', value: CreditasRealtyRegistryOptions.NO },
    { name: 'Não sabe', value: CreditasRealtyRegistryOptions.DO_NOT_KNOW },
  ];

  readonly realEstateTypeOptions = [
    { name: 'Casa', value: CreditasRealtyTypeOptions.HOUSE },
    { name: 'Apartamento', value: CreditasRealtyTypeOptions.APARTMENT },
    { name: 'Imóvel Comercial', value: CreditasRealtyTypeOptions.COMMERCIAL },
    { name: 'Terreno', value: CreditasRealtyTypeOptions.LAND },
  ];
  readonly opcaoSimNao: BasicStructure[] = [
    { id: 'sim', name: 'Sim' },
    { id: 'nao', name: 'Não' },
  ];

  verifyIsDate = (param: any): boolean => (param ? isDate(param) ?? false : false);
  timestampForDate = (data: any): Date => timestampForDate(data) ?? null;

  async ngOnInit(): Promise<void> {
    if (this.customer?.additionalData?.birthDate) {
      this.birthDateControl.setValue(new Date(this.customer?.additionalData?.birthDate) ?? null);
    } else if (this.customer?.birthDate) {
      this.birthDateControl.setValue(timestampForDate(this.customer.birthDate));
    }

    this.loggedAdminSubscription = this.authService.user.subscribe((user) => {
      this.loggedAdmin = user;
    });

    this.customerUID = this.customer.uid;
    this.agreedTerms = this.customer.agreedTerms;
    this.createdBy = {
      uid: this.loggedUser.uid,
      name: this.loggedUser.name || 'Unknown',
      email: this.loggedUser.email,
    };
    this.onCloseOppsForm();

    this.getActiveMotivesSubscription = this.cadastrosGeraisService
      .getActiveMotives()
      .subscribe((reasonsForRefusal) => {
        reasonsForRefusal.map((reason) => {
          reason['id'] = reason.mnemonico;
          reason['value'] = reason.nome;
          delete reason.ativo;
          delete reason.mnemonico;
          delete reason.nome;
        });
        reasonsForRefusal.sort((reason1, reason2) => (reason1?.position > reason2?.position ? 1 : -1));

        this.closingReasonOptions = reasonsForRefusal;
      });

    this.getOpportunityHistoryByCustomer();

    // this.fillAdditionalData();
    await this.getOpportunities(this.opportunities);
    this.getInstitutions();

    
    const hasDifferentProbability = this.opportunities.some(opportunity => {
      return opportunity.stageOpportunity.defaultprobability !== 0 && 
             opportunity.stageOpportunity.defaultprobability !== 100;
    });

    if(this.startCommittee){
      this.updateOpportunitiesStageWithCommitteeStage();
    }
    
    if (hasDifferentProbability) {
      this.hasDifferentProbabilityButton = true
      console.log("Existe pelo menos uma oportunidade com defaultprobability diferente de 0 ou 100.");
    } else {
      this.hasDifferentProbabilityButton = false
      console.log("Todas as oportunidades têm defaultprobability igual a 0 ou 100.");
    }

  }

  ngOnChanges(data: SimpleChanges): void {
    if (data.customer && !data.customer.isFirstChange()) {
      this.ngOnInit();
    }
  }
  ngOnDestroy(): void {
    if (this.bankerNotesSubscription) {
      this.bankerNotesSubscription.unsubscribe();
    }
    if (this.linesCreditActiveSubscription) {
      this.linesCreditActiveSubscription.unsubscribe();
    }
    if (this.institutionsActivesSubscription) {
      this.institutionsActivesSubscription.unsubscribe();
    }
    if (this.getActiveMotivesSubscription) {
      this.getActiveMotivesSubscription.unsubscribe();
    }
    if (this.oppHistorySubscription) {
      this.oppHistorySubscription.unsubscribe();
    }
  }

  //// Forms
  onCloseOppsForm(): void {
    this.closeOppsFormGroup = this.formBuilder.group({
      stageCtrl: new FormControl({ value: null, disabled: false }, Validators.required),
    });
  }

  resetCloseOppsForm(): void {
    this.closeOppsFormGroup.get('stageCtrl')?.reset();
    this.closeOppsFormGroup.get('stageCtrl')?.setErrors(null);
    this.closeOppsFormGroup.get('stageCtrl')?.markAsUntouched();
  }

  fillAdditionalData(): void {
    if (this.customer?.additionalData) {
      const data = this.customer?.additionalData;

      if (data.autoData) {
        this.vehicle = {
          year: data.autoData.year,
          manufacturer: data.autoData.manufacturer,
          model: undefined,
        };

        this.getCarManufacturers()
          .then(() => {
            this.getCarModels()
              .then(() => {
                console.log('Loaded car info');
                this.vehicle.model = _.find(this.models, ['model', data.autoData.model]);
              })
              .catch((err) => {
                console.error('Error filling car model', err);
              });
          })
          .catch((err) => {
            console.error('Error filling car manufacturer', err);
          });
      }

      if (data.realEstateData) {
        this.realEstate = data.realEstateData;
      }

      if (data.birthDate) {
        this.birthDateControl.setValue(_moment(data.birthDate));
      }
    }
  }

  //// Create Opportunities
  onAddNewLinhaCredito(): void {
    if (this.agreedTerms) {
      this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Adicionar linha de crédito',
            alertDescription: 'Deseja realmente associar essa(s) linha(s) de crédito a esse usuário?',
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            // let maxId: number = _.max(this.dataSource.data.map((o) => o.id));
            // maxId = maxId + 1;
            this.isAddingCreditLine = true;
            const newOpps = [];
            this.linhasCredAtivosSelecionados.selectedOptions.selected.forEach((item) => {
              const lineCredit: Opportunity = item.value;
              lineCredit.lineAdditional = true;
              lineCredit.lineActive = false;

              if (!this.opportunities) {
                this.opportunities = [];
              }

              this.opportunities.push(lineCredit);
              newOpps.push(lineCredit);
              // maxId = maxId + 1;
            });
            const createdBy = {
              uid: this.loggedUser.uid,
              name: this.loggedUser.displayName || this.loggedUser.name
            };
            this.oppService
              .createOpportunities(this.customer.uid, newOpps, 'manual', createdBy)
              .then(async () => {
                await this.ngOnInit();
                console.log('We are getting here');
                this.isAddingCreditLine = false;
                // verify if chage stage for stage open bace
                this.needToCheckDocuments.emit();
              })
              .catch((error) => {
                this.logger.error('Problemas ao tentar adicionar nova linha de crédito', this.customer.uid, error);
                this.showMessage(
                  `Erro ao Criar nova Opportunidade`,
                  `Problemas ao tentar adicionar nova linha de crédito.`
                );
                this.isAddingCreditLine = false;
              });
          }
        });
    } else {
      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Usuário sem contrato',
          alertDescription:
            'Não é possível associar uma novas linhas de crédito para usuários que ainda não assinaram o contrato.',
          isOnlyConfirm: true,
        },
      });
    }
  }

  //// read data
  getInstitutions(): void {
    this.institutionsActivesSubscription = this.institutionService
      .getInstituicoesFinanceirasAtivas()
      .subscribe((institutions) => {
        this.institutions = institutions;
        if (this.institutions?.length > 0) {
          this.getLineCreditActive();
        }
      });
  }

  getOpportunityHistoryByCustomer(): void {
    this.oppHistorySubscription = this.oppHistoryService.getOpportunityHistoryByCustomer(this.customerUID).subscribe(res => {
      this.groupOpps.push(...res);
    });
  }

  async updateOpportunitiesStageWithCommitteeStage(): Promise<void> {
    if (!this.opportunities || !this.institutions || this.opportunities.length === 0 || this.institutions.length === 0) {
      console.error('Nenhuma oportunidade ou instituição encontrada.');
      return;
    }
  
    const successMessages: string[] = [];
    const errorMessages: string[] = [];
  
    // Array de institutionKey que exigem uma chamada adicional
    const specialInstitutionKeys = [
      'credihome',
      'flip',
      'gyra',
      'biz_capital',
      'creditas',
      'bom_capital',
      'nexoos',
      'bcredi',
      'banco_btg_pactual_s_a',
      'q_flash',
      'tomatico',
      'cora',
      'adiante_sa',
      '123_qred_solucoes_financeiras_ltda',
      'peak',
      'finanzero',
      'well_bsd_fomento_mercantil_limitada'
    ];
  
    // Itera sobre cada oportunidade para encontrar a instituição correspondente
    for (const opportunity of this.opportunities) {
      const correspondingInstitution = this.institutions.find(
        institution => institution.nomeNoSistema === opportunity.institutionKey
      );
  
      if (correspondingInstitution) {
        // Verifica se o institutionKey está na lista de chaves especiais para chamar a integração primeiro
        if (specialInstitutionKeys.includes(opportunity.institutionKey)) {
          try {
            // Chama o método de integração
            await this.saveDataAndIntegrateInstitutionComittee(opportunity.institutionKey);
          } catch (error) {
            // Se a integração falhar, adiciona a mensagem de erro e continua para a próxima oportunidade
            errorMessages.push(`Erro ao integrar a instituição: ${opportunity.institutionKey}. Oportunidade ${opportunity.institutionName} não atualizada.`);
            continue;
          }
        }
  
        // Se a integração for bem-sucedida ou não for necessária, prossegue com a alteração de estágio
        try {
          opportunity.stageOpportunity = this.oppService.getOppStageFromNumber(correspondingInstitution.committeeStage);
          await this.onChangeStageComittee(opportunity); // Atualiza o estágio da oportunidade
  
          // Adiciona uma mensagem de sucesso ao array
          successMessages.push(`Oportunidade ${opportunity.institutionName} atualizada com sucesso!`);
        } catch (error) {
          // Em caso de erro ao alterar o estágio, adiciona uma mensagem de erro ao array
          errorMessages.push(`Erro ao atualizar o estágio da oportunidade ${opportunity.institutionName}.`);
        }
      } else {
        // Adiciona uma mensagem de erro ao array se a instituição não for encontrada
        errorMessages.push(`Nenhuma instituição encontrada para a chave: ${opportunity.institutionKey}`);
      }
    }
  
    // Exibe todas as mensagens acumuladas
    const messagesToShow = [
      ...successMessages.map(msg => `✔️ ${msg} <br>`), // Adiciona um ícone de sucesso
      ...errorMessages.map(msg => `❌ ${msg} <br>`), // Adiciona um ícone de erro
    ];
  
    this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: 'Resultados da Atualização de Oportunidades',
        alertDescription: messagesToShow.join('\n'), // Exibe todas as mensagens em uma lista
      },
    });
  
  }
  
  

  
  async getOpportunities(opportunities?: Opportunity[]): Promise<void> {
    this.runOpportunities = true;
    this.oppGroupSelected = this.groupOpps.find(g => g.id === 'lines-active');

    if (opportunities && opportunities.length > 0) {
        this.opportunities = opportunities;
        this.oppGroupSelected.opportunities = opportunities;
    } else {
        this.opportunities = await this.oppService.getOpportunities(this.customerUID);
        this.oppGroupSelected.opportunities = this.opportunities;
    }

    // Atualiza o campo lastStageChangeDate para cada oportunidade
    if (this.oppGroupSelected.opportunities && this.oppGroupSelected.opportunities.length > 0) {
        // Cria um array de promessas para buscar a fase de oportunidade para cada oportunidade
        const updatePromises = this.oppGroupSelected.opportunities.map(async (opp) => {
            const oppPhases = await this.oppPhasesService.getOpportunityPhases((opp.uid || this.customerUID), opp.institutionKey);
            
            if (oppPhases.length > 0) {
                // Obtém a data da última mudança de estágio
                const lastStageChangeDate = oppPhases[0].stageChangeDate.toDate(); // Converte de Timestamp para Date
                opp.lastStageChangeDate = lastStageChangeDate;
            }
        });

        // Aguarda que todas as promessas sejam resolvidas
        await Promise.all(updatePromises);
    }

    // Atualiza outras propriedades relacionadas
    if (this.customer && this.oppGroupSelected.opportunities.length > 0) {
        if (this.opportunities[0].limitCredit > 0) {
            this.limiteCreditoStr = UtilHandler.getFormattedPrice(this.opportunities[0].limitCredit);
            this.limiteCredito = this.oppGroupSelected.opportunities[0].limitCredit;
        } else {
            this.limiteCreditoStr = 'R$ 0,00';
            this.limiteCredito = 0;
        }
    }

    this.changeOpportunityView('lines-active');

    if (this.institutions?.length > 0) {
        this.getLineCreditActive();
    }

    this.runOpportunities = false;
}



  onChangeGroupSelected(groupId: string): void {
    this.changeOpportunityView(groupId);
  
    if (groupId != 'lines-active') {
      this.showButton = false;
      this.showDate = false;
    }else{
      this.showDate = true;
      this.showButton = true;
    }

    console.log(groupId)
  }

  changeOpportunityView(groupId: string): void {
    const groupSeled = this.groupOpps.find(g => g.selected);
    this.oppGroupSelected = this.groupOpps.find(g => g.id === groupId);

    groupSeled.selected = false;
    this.oppGroupSelected.selected = true;

    if (this.oppGroupSelected.opportunities?.length > 0) {
      this.changeMatTableDataSource(this.oppGroupSelected.opportunities);
    } else {
      this.dataSource = new MatTableDataSource([]);
    }
  }

  getOpportunityPhases(opp: Opportunity): void {

    this.oppPhasesService.getOpportunityPhases(opp.uid, opp.institutionKey).then((res) => {
      if (res.length > 0) {
        this.dialog.open(TableDialogComponent, {
          minWidth: '650px',
          maxWidth: '800px',
          data: {
            alertTitle: opp.institutionName,
            dataSource: res
          }
        });
      } else {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao buscar histórico',
            alertDescription: 'A oportunidade não possui histórico de fases',
            isOnlyConfirm: true,
          },
        });
      }
    });
  }

  private changeMatTableDataSource(opportunities: Opportunity[]): void {
    this.dataSource = new MatTableDataSource(
      opportunities.sort((o1, o2) => (o1?.institutionName >= o2?.institutionName ? 1 : -1))
    );
    this.dataSource.sort = this.matSortCredit;
    this.dataSource.sortingDataAccessor = (item, property): any => {
      switch (property) {
        case 'actions':
          return item.stageOpportunity.defaultprobability;
        default:
          return item[property];
      }
    };
  }

  getLineCreditActive(): void {
    console.log(`Linhas Credito ${ new Date().toISOString() }`);
    const institutionKeyArray = this.opportunities?.length > 0 ? this.opportunities?.map((op) => op.institutionKey) ?? [] : [];
    this.linesCreditActive = this.institutions
      ?.filter((inst) => !institutionKeyArray.includes(inst.nomeNoSistema) && inst.nomeNoSistema && inst.id)
      .map((inst) => ({
        stageOpportunity: this.oppService.getOppStageFromNumber(inst?.defaultInitialStage ?? 0),
        institutionId: inst?.id,
        institutionKey: inst?.nomeNoSistema,
        institutionName: inst?.nome,
        modality:
          inst?.modalidade.length > 0
            ? inst?.modalidade.map(
              (m) =>
              ({
                id: m?.id,
                name: m?.nome,
                mnemonico: m?.mnemonico,
                active: m?.ativo,
              } as BasicRegister)
            )
            : null,
        modalityStr: inst?.modalidade.map((o) => o?.nome).join(', '),
        deadlineForMonth: parseInt(inst?.prazo?.replace(/\D/g, ''), 10),
        guarantees: inst?.garantias?.map((o) => o?.nome).join(', '),
        accountInstitutionSF: inst?.contaBancariaSalesforce,
        value: this.financHandler.obterValorOperacao(inst, this.customer.revenue),
        lineAdditional: true,
        logoPath: inst?.arquivoInfo?.path ?? null,
      })) as Opportunity[];

    this.linesCreditActive = _.sortBy(this.linesCreditActive, ['institutionKey']);
  }

  getLogoPath(opportunity: Opportunity): string {
    const opp = this.opportunities.find((op) => op?.institutionKey === opportunity.institutionKey) ?? null;
    if (!opp) {
      return '';
    }
    const logoPath =
      this.institutions?.find((i) => i.nomeNoSistema === opportunity?.institutionKey)?.arquivoInfo?.path ?? null;
    if (!logoPath) {
      return '';
    }
    opp.logoPath = logoPath;
    return logoPath;
  }

  //// Update Opportunities
  onChangeStage(opportunity: Opportunity): void {
    let dialogData = null;
    if (this.agreedTerms) {
      // close oportunity
      if (opportunity.stageOpportunity.defaultprobability === 0) {
        const dataSelectListMotivo = {
          placeholder: 'Motivo',
          errorMessage: 'O motivo é obrigatório.',
          dataList: this.closingReasonOptions,
        };
        dialogData = {
          alertTitle: 'Fechado e Perdido',
          alertDescription: `Para alterar essa fase é necessário informar um motivo.`,
          hasSelectList: true,
          dataSelectList: dataSelectListMotivo,
        };

        opportunity.lineClosed = true;
      }
      this.loadingOpportunity = opportunity.institutionKey;
      this.dialog
        .open(AlertDialogComponent, {
          minWidth: '500px',
          maxWidth: '650px',
          data: dialogData || {
            alertTitle: 'Alteração da linha de crédito',
            alertDescription: `Deseja realmente alterar a fase dessa linha de crédito?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            if (opportunity.stageOpportunity.defaultprobability === 0) {
              opportunity.motivationOfCancellation = result.value;
            }
            const createdBy = {
              uid: this.loggedUser.uid,
              name: this.loggedUser.displayName || this.loggedUser.name
            };

            this.oppService
              .updateOpportunity(this.customer.uid, opportunity, createdBy)
              .then(() => {
                // verify if chage stage for stage open bace
                this.needToCheckDocuments.emit();
              })
              .catch((error) => {
                this.logger.error(
                  'Problemas ao tentar alterar a fase de uma linha de crédito',
                  this.customer.email,
                  error
                );
              })
              .finally(() => {
                this.loadingOpportunity = null;
              });
          } else {
            this.loadingOpportunity = null;
          }
        });
    } else {
      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Usuário sem contrato',
          alertDescription:
            'Não é possível alterar a linha de crédito para usuários que ainda não assinaram o contrato.',
          isOnlyConfirm: true,
        },
      });
    }
  }

  onChangeStageComittee(opportunity: Opportunity): void {
    if (this.agreedTerms) {
      // Fechar oportunidade se a probabilidade for 0 (perdida)
      if (opportunity.stageOpportunity.defaultprobability === 0) {
        if (!this.closingReasonOptions || this.closingReasonOptions.length === 0) {
          console.error('A lista de motivos para fechamento está vazia.');
          return;
        }
      }
  
      const createdBy = {
        uid: this.loggedUser.uid,
        name: this.loggedUser.displayName || this.loggedUser.name
      };
  
      this.loadingOpportunity = opportunity.institutionKey;
  
      this.oppService
        .updateOpportunity(this.customer.uid, opportunity, createdBy)
        .then(() => {
          this.needToCheckDocuments.emit(); // Verifica se há documentos a serem checados
        })
        .catch((error) => {
          this.logger.error(
            'Problemas ao tentar alterar a fase de uma linha de crédito',
            this.customer.email,
            error
          );
        })
        .finally(() => {
          this.loadingOpportunity = null;
        });
    } else {
      console.error('Não é possível alterar a linha de crédito para usuários que ainda não assinaram o contrato.');
    }
  }
  

  onSaveDetalhes(opportunity: Opportunity): void {
    if (this.agreedTerms) {
      if (!opportunity.limitOperation || opportunity.limitOperation <= this.limiteCredito) {
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Alteração da linha de crédito',
              alertDescription: `Deseja realmente salvar as alterações realizadas para essa linha de crédito do usuário.`,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.oppService.updateOpportunity(this.customer.uid, opportunity);
              console.log(opportunity)
            }
          });
      } else {
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Limite de Crédito',
              alertDescription: `O Limite de Crédito informado é maior que o Limite Pré-aprovado para esse usuário.` +
                `<br>Deseja realmente continuar?`,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              const createdBy = {
                uid: this.loggedUser.uid,
                name: this.loggedUser.displayName || this.loggedUser.name
              };
              this.oppService.updateOpportunity(this.customer.uid, opportunity, createdBy);
              this.onSaveDetalhesHistorico(opportunity);
            }
          });
      }
    } else {
      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Usuário sem termo',
          alertDescription: 'Não é possível alterar a linha de crédito para usuários que ainda não assinaram o termo.',
          isOnlyConfirm: true,
        },
      });
    }
    this.updateValidity(opportunity)
  }


  onSaveDetalhesHistorico(opportunity: Opportunity): void {

    if (this.agreedTerms) {
      if (!opportunity.limitOperation || opportunity.limitOperation <= this.limiteCredito) {
        this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Alteração da linha de crédito',
            alertDescription: `Deseja realmente salvar as alterações realizadas para essa linha de crédito do usuário.`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            console.log(opportunity)
            this.oppService.updateOpportunityHistory(this.customer.uid, opportunity);
            console.log(opportunity)
          }
        });
      }
    }

  }

  adjustedDate(dateString: string): Date {
    const date = new Date(dateString);
    date.setHours(date.getHours());
    return date;
  }

  adjustedDatePlusThree(dateString: string): Date {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 3);
    return date;
  }

  reopenOpportunity(opportunity: Opportunity): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Reabertura da linha de crédito',
          alertDescription: `Deseja realmente reabrir essa linha de crédito?`,
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.loadingOpportunity = opportunity.institutionKey;
          try {
            const institution = this.institutions.find((i) => i.nomeNoSistema === opportunity.institutionKey);
            opportunity.lineClosed = false;
            opportunity.stageOpportunity = this.oppService.getOppStageFromNumber(
              institution?.defaultInitialStage ?? 10
            );
            try {
              const createdBy = {
                uid: this.loggedUser.uid,
                name: this.loggedUser.displayName || this.loggedUser.name
              };

              await this.oppService.updateOpportunity(this.customer.uid, opportunity, createdBy);
              this.logger.log('Fase da linha de crédito alterada com sucesso');

            } catch (err) {
              opportunity.lineClosed = true;
              opportunity.stageOpportunity = this.oppService.getOppStageFromNumber(0);
              this.logger.error(
                'Problemas ao tentar reabrir a linha de crédito',
                this.customer.uid,
                opportunity.institutionName,
                err
              );
            }
          } catch (err) {
            this.logger.error(
              'Problemas ao tentar reabrir a linha de crédito',
              this.customer.uid,
              opportunity.institutionName,
              err
            );
          } finally {
            this.loadingOpportunity = null;
          }
        } else {
          this.loadingOpportunity = null;
        }
      });
  }

  deleteOpportunity(opportunity: Opportunity): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Apagar linha de crédito',
          alertDescription: `Deseja realmente apagar essa linha de crédito?`,
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.loadingOpportunity = opportunity.institutionKey;
          try {
            await this.oppService.deleteOpp(opportunity);
          } catch (err) {
            this.logger.error(
              'Problemas ao tentar apagar a linha de crédito',
              this.customer.uid,
              opportunity.institutionName,
              err
            );
          } finally {
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Linha apagada com sucesso',
                alertDescription: 'Sucesso ao apagar a linha de crédito',
                isOnlyConfirm: true,
              },
            });
            this.loadingOpportunity = null;
          }
        } else {
          this.loadingOpportunity = null;
        }
      });
  }

  onCloseOpportunities(): void {
    const dataSelectListMotivo = {
      placeholder: 'Motivo',
      errorMessage: 'O motivo é obrigatório.',
      dataList: this.closingReasonOptions,
    };
    this.dialog
      .open(AlertDialogComponent, {
        minWidth: '500px',
        maxWidth: '650px',
        data: {
          alertTitle: 'Fechar linhas de crédito',
          alertDescription: `Para fechar as linhas de crédito é necessário informar um motivo.`,
          hasSelectList: true,
          dataSelectList: dataSelectListMotivo,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const { stageCtrl: stage } = this.closeOppsFormGroup.getRawValue() as { stageCtrl: number };
          if (!!stage) {
            this.runCloseOpps = true;
            this.oppService
              .closeOpportunities(this.customer.uid, stage, result.value)
              .then(() =>
                this.showMessage(
                  `Linhas de Crédito Fechadas com Sucesso`,
                  `Linhas de Crédito em ${ stage }% para baixo foram fechadas com sucesso.`
                )
                  .afterClosed()
                  .subscribe(() => {
                    this.resetCloseOppsForm();
                    this.ngOnInit();
                  })
              )
              .catch((error) => {
                this.showMessage(
                  `Erro ao Fechar Linhas de Crédito`,
                  `Ocorreu um erro ao tentar fechar as linhas de crédito .`
                ).afterClosed();
                console.log('Error ao Fechar Linhas de Crédito: ', error);
              })
              .finally(() => {
                this.runCloseOpps = false;
              });
          }
        }
      });
  }

  //// Integration
  integrateBizCapital(): void {
    this.ums
      .integrateInstitution('biz_capital', this.customer.uid)
      .then(() => {
        this.showIntegrationSuccessMessage();
      })
      .catch((err) => {
        this.showIntegrationError(err.status, err.statusText);

        console.error('Error executing integration', err);
      })
      .finally(async () => await this.getOpportunities());
  }
  integrateTomatico(): void {
    this.ums
      .integrateInstitution('tomatico', this.customer)
      .then(() => {
        this.showIntegrationSuccessMessage();
      })
      .catch((err) => {
        this.showIntegrationError(err.status, err.statusText);
        console.error('Error executing integration', err);
      })
      .finally(async () => await this.getOpportunities());
  }

  // Integration and Save Adtional Data Customer
  saveDataAndIntegrateNexoos(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo salvar os dados e executar esta integração?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingIntegration = true;
          this.ums
            .integrateCreditInstitution(
              {
                ...this.customer,
                additionalData: { ...this.customer.additionalData },
              },
              'nexoos'
            )
            .then(() => {
              console.log('Finish Nexoos');
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Integração realizada',
                  alertDescription: `O lead foi cadastrado na Nexoos.`,
                  isOnlyConfirm: true,
                },
              });
              this.isProcessingIntegration = false;
            })
            .catch((err) => {
              console.error('Error Nexoos', err);

              if (err === 'error-saving-on-firebase') {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao salvar informações',
                    alertDescription: `Ocorreu um erro ao salvar as informações no banco de dados. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              } else {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao integrar',
                    alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              }

              this.isProcessingIntegration = false;
            })
            .finally(async () => await this.getOpportunities(this.opportunities));
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  saveDataAndIntegrateInstitution(instName: string): void {
    let endpointUrl = '/credit/process-credit-institution';
    if (instName === 'banco_btg_pactual_s_a') {
      endpointUrl = '/btg/credit-processing';
    }
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo salvar os dados e executar esta integração?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingIntegration = true;
          this.ums
            .integrateCreditInstitution(
              {
                ...this.customer,
                additionalData: { ...this.customer.additionalData },
              },
              instName,
              endpointUrl
            )
            .then(() => {
              console.log('Finish Institution');
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Integração realizada',
                  alertDescription: `O lead foi cadastrado na ${ instName.toUpperCase() }`,
                  isOnlyConfirm: true,
                },
              });
              this.isProcessingIntegration = false;
            })
            .catch((err) => {
              console.error('Error Institution', instName, err);

              if (err === 'error-saving-on-firebase') {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao salvar informações',
                    alertDescription: `Ocorreu um erro ao salvar as informações no banco de dados. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              } else {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao integrar',
                    alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              }

              this.isProcessingIntegration = false;
            })
            .finally(async () => await this.getOpportunities());
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  saveDataAndIntegrateInstitutionComittee(instName: string): void {
    let endpointUrl = '/credit/process-credit-institution';
    if (instName === 'banco_btg_pactual_s_a') {
      endpointUrl = '/btg/credit-processing';
    }
  
    

      this.isProcessingIntegration = true;
  
      this.ums
        .integrateCreditInstitution(
          {
            ...this.customer,
            additionalData: { ...this.customer.additionalData },
          },
          instName,
          endpointUrl
        )
        .then(() => {
          this.isProcessingIntegration = false;
        })
        .catch((err) => {
          console.error('Error Institution', instName, err);
          if (err === 'error-saving-on-firebase') {
           console.log('Ocorreu um erro ao salvar as informações no banco de dados. Por favor, tente novamente mais tarde.');
          } else {
            console.log('Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.');
          }
          this.isProcessingIntegration = false;
        })
        .finally(async () => await this.getOpportunities());
  }
  

  saveDataAndIntegrateMoneyMoney(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo salvar os dados e executar esta integração?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingIntegration = true;
          this.ums
            .integrateMoneyMoney({
              ...this.customer,
              additionalData: { ...this.customer.additionalData },
            })
            .then(() => {
              console.log('Finish MoneyMoney');
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Integração realizada',
                  alertDescription: `O lead foi cadastrado na MoneyMoney.`,
                  isOnlyConfirm: true,
                },
              });
              this.isProcessingIntegration = false;
            })
            .catch((err) => {
              console.error('Error MoneyMoney', err);

              if (err === 'error-saving-on-firebase') {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao salvar informações',
                    alertDescription: `Ocorreu um erro ao salvar as informações no banco de dados. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              } else {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao integrar',
                    alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              }

              this.isProcessingIntegration = false;
            })
            .finally(async () => await this.getOpportunities());
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }
  saveDataAndIntegrateIouu(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo salvar os dados e executar esta integração?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingIntegration = true;

          // colocar os dados adicionais na constante abaixo no futuro.
          // NÃO APAGAR apagar o exemplo a seguir, nem o uso como param em integrateIouu logo abaixo!

          // const additionalData: any = {
          //   birthDate: this.birthDate?.format('YYYY-MM-DD'),
          // };

          // if (this.hasSpecificGuarantee('imovel-operacional') || this.hasSpecificGuarantee('imovel-nao-operacional')) {
          //   additionalData.realEstateData = {
          //     state: this.bcrediRealty.warrantyState,
          //     address: this.bcrediRealty.warrantyAddress,
          //     zipCode: this.bcrediRealty.warrantyZipCode,
          //     value: this.bcrediRealty.value,
          //     type: this.bcrediRealty.type,
          //   };
          // }

          this.ums
            .integrateIouu({
              ...this.customer,
              additionalData: { ...this.customer.additionalData }, // verificar que dados estao vindo do html
            })
            .then(() => {
              console.log('Finish Iouu');
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Integração realizada',
                  alertDescription: `O lead foi cadastrado na Iouu via integração.`,
                  isOnlyConfirm: true,
                },
              });
              this.isProcessingIntegration = false;
            })
            .catch((err) => {
              console.error('Error Iouu', err);

              if (err === 'error-saving-on-firebase') {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao salvar informações',
                    alertDescription: `Ocorreu um erro ao salvar as informações no banco de dados. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              } else if (err.status === 422) {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Status Integração',
                    alertDescription: `O lead já foi cadastrado na Iouu.`,
                    isOnlyConfirm: true,
                  },
                });
              } else {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao integrar',
                    alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              }

              this.isProcessingIntegration = false;
            })
            .finally(async () => await this.getOpportunities());
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }
  saveDataAndIntegrateAdianteSA(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo salvar os dados e executar esta integração?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingIntegration = true;
          this.ums
            .integrateAdianteSA({
              ...this.customer,
              additionalData: { ...this.customer.additionalData },
            })
            .then(() => {
              console.log('Finish Adiante SA');
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Integração realizada',
                  alertDescription: `O lead foi cadastrado na Adiante.`,
                  isOnlyConfirm: true,
                },
              });
              this.isProcessingIntegration = false;
            })
            .catch((err) => {
              console.error('Error Adiante SA', err);

              if (err === 'error-saving-on-firebase') {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao salvar informações',
                    alertDescription: `Ocorreu um erro ao salvar as informações no banco de dados. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              } else if (err.status === 422) {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Status Integração',
                    alertDescription: `O lead teve sua situação REPROVADA na Adiante.`,
                    isOnlyConfirm: true,
                  },
                });
              } else if (err.status === 406) {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Status',
                    alertDescription: `O lead não possui todos os dados necessários para essa integração`,
                    isOnlyConfirm: true,
                  },
                });
              } else if (err.status === 409) {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao integrar',
                    alertDescription: `Ocorreu um erro ao executar a integração. O lead já foi cadastrado na Adiante.`,
                    isOnlyConfirm: true,
                  },
                });
              } else {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao integrar',
                    alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              }

              this.isProcessingIntegration = false;
            })
            .finally(async () => await this.getOpportunities());
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }
  saveDataAndIntegrateQFlash(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo salvar os dados e executar esta integração?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingIntegration = true;
          this.ums
            .integrateQFlash({
              ...this.customer,
              additionalData: { ...this.customer.additionalData },
            })
            .then(() => {
              console.log('Finish QFlash');
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Integração realizada',
                  alertDescription: `O lead foi cadastrado na QFlash.`,
                  isOnlyConfirm: true,
                },
              });
              this.isProcessingIntegration = false;
            })
            .catch((err) => {
              console.error('Error QFlash', err);

              if (err === 'error-saving-on-firebase') {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao salvar informações',
                    alertDescription: `Ocorreu um erro ao salvar as informações no banco de dados. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              } else {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao integrar',
                    alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              }

              this.isProcessingIntegration = false;
            })
            .finally(async () => await this.getOpportunities());
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }
  saveDataAndIntegrateCreditas(): void {
    if (this.canIntegrateCreditas()) {
      const confirmSubscription = this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Confirmação',
            alertDescription: `Deseja mesmo salvar os dados e executar esta integração?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.isProcessingIntegration = true;

            const additionalData: any = {
              birthDate: this.birthDateControl.value?.format('YYYY-MM-DD'),
            };

            if (this.hasSpecificGuarantee('veiculos')) {
              additionalData.autoData = {
                year: this.vehicle.year,
                manufacturer: this.vehicle.manufacturer,
                model: this.vehicle.model?.model,
                value: this.vehicle.model?.value,
              };
            } else if (
              this.hasSpecificGuarantee('imovel-operacional') ||
              this.hasSpecificGuarantee('imovel-nao-operacional')
            ) {
              additionalData.realEstateData = {
                value: this.realEstate.value,
                debt: this.realEstate.debt,
                type: this.realEstate.type,
                hasDeed: this.realEstate.hasDeed,
                zipCode: this.realEstate.zipCode,
                address: this.realEstate.address,
                number: this.realEstate.number,
                neighborhood: this.realEstate.neighborhood,
                city: this.realEstate.city,
                state: this.realEstate.state?.toUpperCase(),
                country: this.realEstate.country,
              };
            }

            this.ums
              .integrateCreditas({
                ...this.customer,
                additionalData: { ...this.customer.additionalData, ...additionalData },
              })
              .then(() => {
                console.log('Finish Creditas');
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Integração realizada',
                    alertDescription: `O lead foi cadastrado na Creditas via integração.`,
                    isOnlyConfirm: true,
                  },
                });
                this.isProcessingIntegration = false;
              })
              .catch((err) => {
                console.error('Error Creditas', err);

                if (err === 'error-saving-on-firebase') {
                  this.dialog.open(AlertDialogComponent, {
                    maxWidth: '600px',
                    data: {
                      alertTitle: 'Erro ao salvar informações',
                      alertDescription: `Ocorreu um erro ao salvar as informações no banco de dados. Por favor, tente novamente mais tarde.`,
                      isOnlyConfirm: true,
                    },
                  });
                } else if (err === 'duplicated-lead') {
                  this.dialog.open(AlertDialogComponent, {
                    maxWidth: '600px',
                    data: {
                      alertTitle: 'Erro - Lead já cadastrado',
                      alertDescription: `O lead já foi cadastrado anteriormente na Creditas.`,
                      isOnlyConfirm: true,
                    },
                  });
                } else {
                  this.dialog.open(AlertDialogComponent, {
                    maxWidth: '600px',
                    data: {
                      alertTitle: 'Erro ao integrar',
                      alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                      isOnlyConfirm: true,
                    },
                  });
                }

                this.isProcessingIntegration = false;
              })
              .finally(async () => await this.getOpportunities());
          }

          if (confirmSubscription) {
            confirmSubscription.unsubscribe();
          }
        });
    }
  }

  saveDataAndIntegrateCredihome(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo salvar os dados e executar esta integração?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingIntegration = true;
          const birthDateValue = this.birthDateControl.value;
          const additionalData: any = {
            birthDate: birthDateValue instanceof Date ? birthDateValue.toISOString().split('T')[0] : birthDateValue.format('YYYY-MM-DD')
          };

          this.ums
            .integrateCredihome({
              ...this.customer,
              birthDate: additionalData.birthDate,
              additionalData: { ...this.customer.additionalData, ...additionalData },
            })
            .then(() => {
              console.log('Finish Credihome');
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Integração realizada',
                  alertDescription: `O lead foi cadastrado na Credihome via integração.`,
                  isOnlyConfirm: true,
                },
              });
              this.isProcessingIntegration = false;
            })
            .catch((err) => {
              console.error('Error Credihome', err);

              if (err === 'error-saving-on-firebase') {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao salvar informações',
                    alertDescription: `Ocorreu um erro ao salvar as informações no banco de dados. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              } else if (err === 'duplicated-lead') {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro - Lead já cadastrado',
                    alertDescription: `O lead já foi cadastrado anteriormente na Credihome.`,
                    isOnlyConfirm: true,
                  },
                });
              } else {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao integrar',
                    alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              }

              this.isProcessingIntegration = false;
            })
            .finally(async () => await this.getOpportunities());
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });

  }

  saveDataAndIntegratePeak(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo salvar os dados e executar esta integração?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingIntegration = true;
          this.ums
            .integrateCreditInstitution(
              {
                ...this.customer,
                additionalData: { ...this.customer.additionalData },
              },
              'peak'
            )
            .then(() => {
              console.log('Finish Peak Invest');
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Integração realizada',
                  alertDescription: `O lead foi cadastrado na Peak.`,
                  isOnlyConfirm: true,
                },
              });
              this.isProcessingIntegration = false;
            })
            .catch((err) => {
              console.error('Error Peak', err);

              if (err === 'error-saving-on-firebase') {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao salvar informações',
                    alertDescription: `Ocorreu um erro ao salvar as informações no banco de dados. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              } else {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao integrar',
                    alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });
              }

              this.isProcessingIntegration = false;
            })
            .finally(async () => await this.getOpportunities());
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }
  saveDataAndIntegrateBCredi(): void {
    if (this.canIntegrateBCredi()) {
      const confirmSubscription = this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Confirmação',
            alertDescription: `Deseja mesmo salvar os dados e executar esta integração?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.isProcessingIntegration = true;

            const additionalData: any = {
              birthDate: this.birthDateControl.value?.format('YYYY-MM-DD'),
            };

            if (
              this.hasSpecificGuarantee('imovel-operacional') ||
              this.hasSpecificGuarantee('imovel-nao-operacional')
            ) {
              additionalData.realEstateData = {
                state: this.bcrediRealty.warrantyState,
                address: this.bcrediRealty.warrantyAddress,
                zipCode: this.bcrediRealty.warrantyZipCode,
                value: this.bcrediRealty.value,
                type: this.bcrediRealty.type,
              };
            }

            this.ums
              .integrateBCredi({
                ...this.customer,
                additionalData: { ...this.customer.additionalData, ...additionalData },
              })
              .then(() => {
                console.log('Finish BCredi');
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Integração realizada',
                    alertDescription: `O lead foi cadastrado na BCredi via integração.`,
                    isOnlyConfirm: true,
                  },
                });
                this.isProcessingIntegration = false;
              })
              .catch((err) => {
                console.error('Error BCredi', err);

                if (err === 'error-saving-on-firebase') {
                  this.dialog.open(AlertDialogComponent, {
                    maxWidth: '600px',
                    data: {
                      alertTitle: 'Erro ao salvar informações',
                      alertDescription: `Ocorreu um erro ao salvar as informações no banco de dados. Por favor, tente novamente mais tarde.`,
                      isOnlyConfirm: true,
                    },
                  });
                } else {
                  this.dialog.open(AlertDialogComponent, {
                    maxWidth: '600px',
                    data: {
                      alertTitle: 'Erro ao integrar',
                      alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                      isOnlyConfirm: true,
                    },
                  });
                }

                this.isProcessingIntegration = false;
              });
          }

          if (confirmSubscription) {
            confirmSubscription.unsubscribe();
          }
        });
    }
  }

  // View Status
  verifyCreditInstitutionStatus(instName: string): void {
    console.log('Check company status on institution', instName);
    this.isProcessingIntegration = true;
    this.ums
      .checkCreditInstitutionStatus(
        {
          ...this.customer,
          additionalData: { ...this.customer.additionalData },
        },
        instName
      )
      .then((data) => {
        this.isProcessingIntegration = false;
        const infoStatusInstitution = JSON.parse(data);
        // console.log("Data from check status ums",infoStatusInstitution);
        this.dialog.open(CreditStatusDialogComponent, {
          maxWidth: '600px',
          data: {
            isOnlyConfirm: true,
            institutionName: instName,
            institutionInfo: infoStatusInstitution,
          },
        });
      })
      .catch((err) => {
        this.isProcessingIntegration = false;
        console.error(`Error ${ instName } `, err);
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao verificar status',
            alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
            isOnlyConfirm: true,
          },
        });
      })
      .finally(async () => await this.getOpportunities());
  }

  //// can Integration
  canIntegrateCreditas(): boolean {
    if (this.hasSpecificGuarantee('veiculos')) {
      return this.birthDateControl.value && this.vehicle?.year && this.vehicle?.manufacturer && this.vehicle?.model;
    } else if (this.hasSpecificGuarantee('imovel-operacional') || this.hasSpecificGuarantee('imovel-nao-operacional')) {
      return (
        this.birthDateControl.value &&
        this.realEstate?.hasDeed &&
        this.realEstate?.zipCode?.length === 10 &&
        this.realEstate?.address &&
        this.realEstate?.number &&
        this.realEstate?.neighborhood &&
        this.realEstate?.city &&
        this.realEstate?.state &&
        this.realEstate?.country &&
        this.realEstate?.type &&
        this.realEstate?.value &&
        this.realEstate?.debt !== undefined &&
        this.realEstate?.debt !== null &&
        this.realEstate?.debt !== ''
      );
    } else {
      return false;
    }
  }
  canIntegrateBCredi(): boolean {
    if (this.hasSpecificGuarantee('imovel-operacional') || this.hasSpecificGuarantee('imovel-nao-operacional')) {
      return (
        this.birthDateControl.value &&
        this.bcrediRealty?.value &&
        this.bcrediRealty?.type &&
        this.bcrediRealty?.warrantyZipCode &&
        this.bcrediRealty?.warrantyAddress &&
        this.bcrediRealty?.warrantyState
      );
    } else {
      return false;
    }
  }

  //// Others
  getCarManufacturers(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.manufacturers = [];
      this.models = [];

      this.http
        .get(`${ environment.functionsUrl }/utils/creditas/auto/manufacturers?year=${ this.vehicle?.year }`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((manufacturers: any) => {
          this.manufacturers = _.sortBy(manufacturers, ['name']);
          resolve();
        })
        .catch((err) => {
          console.error('Error getting car manufacturers from Creditas API', err);
          reject(err);
        });
    });
  }
  getCarModels(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.models = [];

      this.http
        .get(
          `${ environment.functionsUrl }/utils/creditas/auto/models?year=${ this.vehicle?.year }&manufacturer=${ this.vehicle?.manufacturer }`,
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then((models: any) => {
          this.models = _.sortBy(models, ['model']);
          resolve();
        })
        .catch((err) => {
          console.error('Error getting car models from Creditas API', err);
          reject(err);
        });
    });
  }
  hasSpecificGuarantee(guarantee: string): boolean {
    return this.customer?.guarantees?.map((g) => g.id).indexOf(guarantee) >= 0;
  }

  //// Notes
  viewMoreBankerNotes(): void {
    this.dialog.open(BankerNotesComponent, {
      data: {
        institution: {
          id: this.expandedElement.institutionKey,
          name: this.expandedElement.institutionName,
        },
        customer: this.customerUID,
        customerData: this.customer,
        admin: this.loggedUser,
        notificationData: {
          companyName: this.customer.companyName,
          zipCode: this.customer.address?.zipCode,
          institution: this.expandedElement.institutionName,
        },
      },
    });
  }
  createBankerNote(institutionName: string): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Salvar registro',
          alertDescription: 'Deseja realmente salvar esse registro de acompanhamento?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const note: BankerNote = {
            ...this.newBankerNote,
            type: 'ADMIN',
            banker: null,
            createdBy: {
              name: this.loggedUser.name,
              email: this.loggedUser.email,
              uid: this.loggedUser.uid,
            },
          };

          this.notesService
            .createBankerNote(this.customerUID, this.expandedElement.institutionKey, note, {
              companyName: this.customer.companyName,
              address: this.customer.address,
              institution: institutionName,
            })
            .then(() => {
              // this.bankerNotes = [{ ...note, createdAt: firestore.Timestamp.now() }, ...this.bankerNotes];
              this.isBankerNoteCreateMode = false;
              this.clearNewBankerNote();
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Registro salvo',
                  alertDescription: 'O registro de acompanhamento foi salvo com sucesso.',
                  isOnlyConfirm: true,
                },
              });
            })
            .catch((err) => {
              console.error('Error creating note', err);

              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao salvar',
                  alertDescription: 'Não foi possível salvar o registro. Tente novamente mais tarde.',
                  isOnlyConfirm: true,
                },
              });
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }
  clearNewBankerNote(): void {
    this.newBankerNote.message = '';
  }
  cancelBankerNote(): void {
    this.isBankerNoteCreateMode = false;
    this.clearNewBankerNote();
  }
  forwardNote(noteText, from: 'AGENT' | 'BANKER'): void {
    this.dialog.open(ForwardNotesComponent, {
      width: '70%',
      data: {
        customer: this.customerUID,
        customerData: this.customer,
        noteText: noteText,
        noteFrom: from,
      },
    });
  }

  //// Dialogs
  showIntegrationSuccessMessage(): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: 'Integração efetuada',
        alertDescription: 'A integração foi realizada com sucesso.',
        isOnlyConfirm: true,
      },
    });
  }
  showIntegrationError(status: number, text?: string): MatDialogRef<AlertDialogComponent> {
    switch (status) {
      case 409:
      case 422:
        return this.dialog.open(AlertDialogComponent, {
          maxWidth: '800px',
          data: {
            alertTitle: 'Cliente já cadastrado',
            alertDescription:
              'Cliente já cadastrado no parceiro. Para verificar se o cadastro foi feito por integração prévia, ' +
              'acesse o painel do parceiro ou entre emn contato com o responsável.',
            isOnlyConfirm: true,
          },
        });
      default:
        return this.dialog.open(AlertDialogComponent, {
          maxWidth: '800px',
          data: {
            alertTitle: 'Erro ao integrar',
            alertDescription: `Não foi possível executar a integração. Status: ${ status } ${ text ? `(${ text })` : '' }`,
            isOnlyConfirm: true,
          },
        });
    }
  }
  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }
  onClickExpanded(): void {
    if (this.expandedElement) {
      this.expandedElementId = this.expandedElement.institutionId;

      this.isLoadingBankerNotes = true;
      this.bankerNotesSubscription = this.notesService
        .getBankerComments({
          customerUid: this.customer.uid,
          isSenderAdmin: 'yes',
          institution: this.expandedElement.institutionKey,
        })
        .subscribe((notes) => {
          this.bankerNotes = notes.slice(0, this.MAX_BANKER_NOTES_PREVIEW_QUANTITY);
          this.isLoadingBankerNotes = false;
        });
    } else {
      this.expandedElementId = null;
    }
  }

  isOppDisabled(operacao: Opportunity) {
    return (
      operacao.lineClosed ||
      operacao.stageOpportunity.defaultprobability === 0 ||
      operacao.stageOpportunity.defaultprobability === 100
    );
  }

  onFocusStage(stageOpportunity: any): void {
    if (stageOpportunity.id !== 'Fechado e perdido' && stageOpportunity.id !== this.selectedValueStage?.id) {
      this.selectedValueStage = stageOpportunity;
    }
  }
  compareOppStage(s1: OpportunityStage, s2: OpportunityStage): boolean {
    return s1.defaultprobability === s2.defaultprobability;
  }

  
  compareObjs(s1: string, s2: string): boolean {
    return s1 === s2;
  }
  

  viewDateClosind(date): Date {
    if (date.nanoseconds) {
      return date.toDate();
    } else {
      return date;
    }

  }


  calcValidityMessage(opportunity: any): string {
    if (opportunity && opportunity.lastStageChangeDate && opportunity.proposedValidity) {
      const validDays = opportunity.proposedValidity;
  
      if (typeof validDays === 'number') {
        const creationDate = new Date(opportunity.lastStageChangeDate);
        const expirationDate = new Date(creationDate);
        expirationDate.setDate(creationDate.getDate() + validDays);
  
        const currentDate = new Date();
        const timeDiff = expirationDate.getTime() - currentDate.getTime();
        const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
  
        if (daysRemaining > 0) {
          return `Faltam ${daysRemaining} dias para expirar, até ${expirationDate.toLocaleDateString()}`;
        } else {
          return `Venceu em ${expirationDate.toLocaleDateString()}`;
        }
      }
    }
    return '-';
  }
  
  
  updateValidity(opp: Opportunity): void {
    
     
      if (opp) {
        
        const creationDate = new Date(opp.customerData.createdAt);
        const expirationDate = new Date(creationDate);
        expirationDate.setDate(creationDate.getDate() + opp.proposedValidity);
      

        const currentDate = new Date();
        const timeDiff = expirationDate.getTime() - currentDate.getTime();
        const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
       
        
        if (daysRemaining > 0) {
          this.validityMessages[opp.key] = `Faltam ${daysRemaining} dias para expirar, até ${expirationDate.toLocaleDateString()}`;
        } else {
          this.validityMessages[opp.key]  = `Venceu em ${expirationDate.toLocaleDateString()}`;
        }

       
      }
  }

  openDialog(): void {
    const date = new Date();
    const dialogRef = this.dialog.open(RecurrenceAdminDialogComponent, {
      width: '650px',
      data: {
        customer: this.customer
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
  
      if (result !== undefined) {
        console.log(result)
        const customerData: Customer = {
          revenue: result,
          hasRecurrence: true,
          lastRecurrenceDate: firestore.Timestamp.now(),
          recurrenceDate: firestore.Timestamp.fromDate(
        _moment(date).toDate()
      )
        }
        this.recurrenceCustomer(customerData);
      }
    });
  }
  
  recurrenceCustomer(customer: any): void {
    this.runReprocesseRecurrence = true;
    this.customerService.updateCustomerByRecurrence(this.customer.uid, customer)
    .then(() => {
      console.log('Cliente atualizado com sucesso.');
    })
    .catch((error) => {
      // Erro na atualização
      this.runReprocesseRecurrence = false;
      console.error('Problemas na alteração do cliente.', error);
    }).finally(() => {
      this.dialog.closeAll();
      this.updateOpportunities();
    });
  }


  updateOpportunities(): void {
    this.oppService.getOpportunities(this.customer.uid)
      .then(async (opportunities: Opportunity[]) => {
        this.opportunities = opportunities;
        await this.getOpportunities(this.opportunities);
        this.getInstitutions();
        this.runReprocesseRecurrence = false;
      })
      .catch((error) => {
        console.error('Erro ao atualizar oportunidades:', error);
        this.runReprocesseRecurrence = false;
      });
  }


}





